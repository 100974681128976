<script setup>
    import MainLayout from '@/views/layouts/MainLayout.vue'
    import RecommendedCourses from '@/components/home/RecommendedCourses.vue'
    import NewestCourses from '@/components/home/NewestCourses.vue'
    import TrendCourses from '@/components/home/TrendCourses.vue'
    import Categories from '@/components/home/Categories.vue'    
    import Slider from '@/components/home/Slider.vue'
    import { onMounted } from '@vue/runtime-core'
    import { axiosInstance } from '@/axios';

    // localStorage.removeItem("AUTH_TOKEN");

    onMounted(() => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.u) {
            localStorage.setItem("AUTH_TOKEN", params.u);
            getCurrentUserInfo();
            location.assign('/')
        }

        if(localStorage.getItem('AUTH_TOKEN')) {
            getCurrentUserInfo();
        }
    })

    const getCurrentUserInfo = () => {        
        axiosInstance.get(`/auth/me`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('AUTH_TOKEN'),
            }
        })
        .then(response => {
            // console.log(response.data.data)
            localStorage.setItem('AUTH_USER', JSON.stringify(response.data.data))
        })
        .catch(error => {
            console.log("error.response", error.response.status);
            if (error.response.status == 401) {
                localStorage.removeItem('AUTH_TOKEN')
                location.assign('/')
            }
        });
    }
</script>

<template>
    <MainLayout>
        
        <Slider />
    
        <div class="mx-auto max-w-5xl p-4" id="get-started">

            <Categories />

            <RecommendedCourses />

            <NewestCourses />

            <TrendCourses />

        </div>
    </MainLayout>
</template>
  