<script setup>
    import { axiosInstance } from '@/axios';
    import { useAxios } from '@vueuse/integrations/useAxios'
    import { ref, onMounted, watchEffect } from 'vue';
    import { useRoute } from 'vue-router';

    const route = useRoute()
    
    const searchInput = ref('')
    const searchInputFocused = ref(false);
    const suggestions = ref([])
    
    
    const fetchSuggestions = () => {
        axiosInstance.get('search/suggest-courses?q=' + searchInput.value)
        .then(response => {
            console.log('fetchSuggestions', response.data)
            suggestions.value = response.data.data;
        })
        .catch(error => {});
    }

    const fetchRecentSearches = () => {
        axiosInstance.get(`/search/my-logs`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('AUTH_TOKEN'),
            }
        })
        .then(response => {
            console.log('fetchRecentSearches', response.data)
            suggestions.value = response.data.data;
        })
        .catch(error => {});
    }

    const onSearchSubmit = (e) => {
        window.location.href = '/courses/newest/?q=' + searchInput.value;
    }

    const onSearchFocusout = () => {
        setTimeout(() => {
            searchInputFocused.value = false;
        }, 500);
    }

    watchEffect(() => {

        if (searchInput.value.length > 2) {
            fetchSuggestions();
        }

        if (searchInput.value.length == 0 && searchInputFocused.value) {
            fetchRecentSearches();
        }

        if (!route.query.q && !searchInputFocused.value) { // clear search input when route query is empty, user is left search page
            searchInput.value = '';
        }
    });

    onMounted(() => {
        if (route.query.q) {
            searchInput.value = route.query.q;
        }
    })
</script>

<template>
    <div class="relative">
    <form action="#" @submit.prevent="onSearchSubmit">
        <div class="relative">
            <span class="absolute inset-y-0 left-0 flex items-center pl-5">
                <button type="submit" class="">
                    <svg class="w-5 h-5 text-gray-400" viewBox="0 0 24 24" fill="none">
                        <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </button>
            </span>
            <input type="text" v-model="searchInput" @focusin="searchInputFocused = true" @focusout="onSearchFocusout" class="search-input w-full py-2 pl-10 pr-4 text-gray-700 border-1 rounded-lg dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-orange-400 dark:focus:border-orange-300 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40" placeholder="البحث...">
        </div>
    </form>

    <div :class="{ 'hidden': !searchInputFocused || (suggestions && suggestions.length == 0) }" class="absolute inset-x-0 z-10 px-2 py-3 mx-5 mt-4 overflow-y-auto bg-white border rounded-md max-h-72 dark:bg-gray-900 dark:border-gray-700">
        <a :href="'/courses/newest/?ordering=newest' + '&q=' + suggestion.query_ar	" v-for="suggestion in suggestions" class="text-right block py-2 hover:bg-gray-100 px-4">
            <h3 class="font-medium text-gray-700 dark:text-gray-100 hover:underline">{{ suggestion.query_ar	 }}</h3>
            <!-- <p class="mt-0 text-sm text-gray-500 dark:text-gray-400">{{ suggestion.author_query }}</p> -->
        </a>
    </div>
    </div>
</template>
